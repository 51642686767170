<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">审核管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf">班级审核</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="margin-bottom: 20px"> 基本信息</h3>
            <div>
              <h4 style="color: red; padding-left: 4.8rem" v-if="Form.advancechargeBalance">
                开班机构为预付款机构,当前余额为{{ Form.advancechargeBalance }}元
              </h4>
              <h4 style="color: red; padding-left: 4.8rem" v-if="projectAdvancecharge">
                班级预扣款{{ projectAdvancecharge }}
                <span v-if="Number(projectAdvancecharge) > 0">元</span>
              </h4>
              <el-form :inline="false" label-width="10rem" class="formCell">
                <el-form-item label="班级名称：" prop="projectName">
                  <p>{{ Form.projectName }}</p>
                </el-form-item>
                <el-form-item label="班级编码：" prop="projectCode">
                  <p>{{ Form.projectCode }}</p>
                </el-form-item>
                <el-form-item label="行政区划：">
                  <p>{{ Form.areaNamePath }}</p>
                </el-form-item>
                <el-form-item label="所属街道：" v-if="Form.street">
                  <p>{{ Form.street }}</p>
                </el-form-item>
                <el-form-item label="有效日期：">
                  <p>
                    {{ Form.startDate | momentDate }} -
                    {{ Form.endDate | momentDate }}
                  </p>
                </el-form-item>
                <el-form-item label="报名截止时间：">
                  <p>{{ Form.deadDate | momentDate }}</p>
                </el-form-item>
                <el-form-item label="负责人：">
                  <p>{{ Form.projectUser }}</p>
                </el-form-item>
                <el-form-item label="负责人电话：">
                  <p>{{ Form.projectUserPhone }}</p>
                </el-form-item>
                <el-form-item label="培训类型：">
                  <p>{{ Form.Trainingtype }}</p>
                </el-form-item>
                <el-form-item label="培训人数：">
                  <p>{{ Form.projectPeople }}</p>
                </el-form-item>
                <el-form-item label="培训地点：">
                  <p>{{ Form.projectAddress || "无" }}</p>
                </el-form-item>
                <el-form-item label="期次：">
                  <p>{{ Form.projectPc || "无" }}</p>
                </el-form-item>
                <el-form-item label="考试机构：">
                  <p>{{ Form.projectPaperComp || "无" }}</p>
                </el-form-item>
                <el-form-item label="考试时间：">
                  <p>{{ Form.projectPaperDate | momentDate }}</p>
                </el-form-item>
                <el-form-item label="人群类型：" v-if="Form.projectCrowd">
                  <p>{{ $setDictionary("CROWDTYPE", Form.projectCrowd) }}</p>
                </el-form-item>
                <el-form-item label="级别：" v-if="Form.subsidyLevel">
                  <p>{{ Form.subsidyLevel }}</p>
                </el-form-item>
                <el-form-item label="项目编号：">
                  <p>
                    {{ Form.projectRecordno }}
                  </p>
                </el-form-item>
                <el-form-item label="备案课时数：">
                  <p>
                    {{ Form.recordClasshours }}
                  </p>
                </el-form-item>
                <el-form-item label="培训方式：" v-if="Form.trainMethod">
                  <p>
                    {{
                      $setDictionary("PROJECT_TRAIN_METHOD", Form.trainMethod)
                    }}
                  </p>
                </el-form-item>
                <el-form-item label="是否班级补贴：">
                  <p>
                    {{
                      Form.isSubsidy ? '是' : '否'
                    }}
                  </p>
                </el-form-item>
                <el-form-item label="申请表图片: " prop="thumbnail">
                  <div v-if="Form.list.length" style="display: flex; flex-direction: column">
                    <span style="color: #409eff" v-for="(item, index) in Form.list" :key="index">
                      {{ item.fileName }}
                      <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px">预览</a>
                    </span>
                  </div>
                  <div v-else>
                    <p>无</p>
                  </div>
                </el-form-item>
                <el-form-item label="班级参数：" v-if="Form.originalParameter">
                  <p style="color: red">
                    {{ Form.originalParameter }}
                  </p>
                  <el-button type="primary" class="bgc-bv" size="small" round @click="checkParameters">查看参数</el-button>
                </el-form-item>
              </el-form>
              <div style="display: flex; color: red; padding-left: 5.5rem" v-if="remindData.length != 0">
                <span>特别提醒: </span>
                <div>
                  <p v-for="(item, index) in remindData" :key="index">
                    {{ index + 1 }}.{{ item.remindText }}
                  </p>
                </div>
              </div>
            </div>
            <el-divider></el-divider>
            <h3 style="margin-top: 20px">基本设置</h3>
            <div>
              <el-form ref="ruleForm" label-width="12rem" inline>
                <el-form-item label="允许学员查看学时证明：">
                  <p>
                    {{ Form.projectClassHourCertificate == true ? "是" : "否" }}
                  </p>
                </el-form-item>
                <el-form-item label="允许学员查看培训证书：">
                  <p>
                    {{ Form.studentCertPrint == true ? "是" : "否" }}
                  </p>
                </el-form-item>
                <!--如果区划是上海并且有值-->
                <el-form-item label="开始考试后最短" v-if="Form.paraExamLimitMinTime && Form.areaId.substring(0, 3) == '310'">
                  <p>
                    {{ Form.paraExamLimitMinTime }}分钟交卷
                  </p>
                </el-form-item>
              </el-form>
              <el-form :inline="false" ref="Form" label-width="12rem" v-if="Form.trainTypeId == '11'">
                <el-form-item label="是否开启问卷调查">
                  <p>{{ Form.enableSurvey == true ? "是" : "否" }}</p>
                </el-form-item>
                <el-form-item label="抽查人数" v-if="Form.enableSurvey">
                  <p>{{ Form.spotCheckNum }}人</p>
                </el-form-item>
              </el-form>
            </div>
            <el-divider></el-divider>
            <h3 style="margin: 20px 0">协议信息（列表中协议有效状态指有无符合当前班级的协议，非协议本身的有效状态）</h3>
            <el-table ref="agreementTable" :data="agreementList" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" :row-class-name="tableRowClassName">
              <el-table-column label="序号" type="index"></el-table-column>
              <el-table-column label="合同有效期" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                  {{ row.startDate }} ~ {{ row.endDate }}
                </template>
              </el-table-column>
              <el-table-column label="签订机构" align="center" show-overflow-tooltip prop="signCompName" />
              <el-table-column label="有效状态" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                  {{ row.validState ? '有效' : '无效' }}
                </template>
              </el-table-column>
              <el-table-column label="合同是否收回" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                  {{ row.isTakeBack ? '已收回' : '未收回' }}
                </template>
              </el-table-column>
              <el-table-column label="附件" align="center">
                <template slot-scope="{ row }">
                  <el-button type="text" size="small" @click="previewTheContractHandle(row.contractId)">预览合同</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
            <h3 style="margin: 20px 0">收款价格</h3>
            <el-form ref="priceSet" :model="Form.saveFdNewBillProjectPriceDTO" :rules="rules" :inline="true" size="small"
              label-width="120px">
              <el-form-item label="收款类型：" prop="paymentType">
                <el-select v-model="Form.saveFdNewBillProjectPriceDTO.paymentType" placeholder="请选择">
                  <el-option label="技术/课程费用分开" value="10">
                  </el-option>
                  <el-option label="技术/课程费用合计" value="20">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="课程价格：" prop="courseFee" v-if="Form.saveFdNewBillProjectPriceDTO.paymentType == '10'">
                <el-input v-model="Form.saveFdNewBillProjectPriceDTO.courseFee" @keyup.native="
                  Form.saveFdNewBillProjectPriceDTO.courseFee = zF.oninput2(
                    Form.saveFdNewBillProjectPriceDTO.courseFee,
                    2
                  )
                  " placeholder="请输入内容"><template slot="append">元/每人</template></el-input>
              </el-form-item>
              <el-form-item label="技术价格：" prop="platformTechnicalFee"
                v-if="Form.saveFdNewBillProjectPriceDTO.paymentType == '10'">
                <el-input v-model="Form.saveFdNewBillProjectPriceDTO.platformTechnicalFee" @keyup.native="
                  Form.saveFdNewBillProjectPriceDTO.platformTechnicalFee = zF.oninput2(
                    Form.saveFdNewBillProjectPriceDTO.platformTechnicalFee,
                    2
                  )
                  " placeholder="请输入内容"><template slot="append">元/每人</template></el-input>
              </el-form-item>
              <el-form-item label="平台服务费：" prop="platformTotalFee"
                v-if="Form.saveFdNewBillProjectPriceDTO.paymentType == '20'">
                <el-input v-model="Form.saveFdNewBillProjectPriceDTO.platformTotalFee" @keyup.native="
                  Form.saveFdNewBillProjectPriceDTO.platformTotalFee = zF.oninput2(
                    Form.saveFdNewBillProjectPriceDTO.platformTotalFee,
                    2
                  )
                  " placeholder="请输入内容"><template slot="append">元/每人</template></el-input>
              </el-form-item>
            </el-form>
            <h3 style="margin: 20px 0">代理价格</h3>
            <el-form :inline="true" size="small" :disabled="!Form.saveFdNewBillProjectPriceDTO.agencyName"
              label-width="100px">
              <el-form-item label="代理商名称：" prop="projectName">
                <span v-if="Form.saveFdNewBillProjectPriceDTO.agencyName">{{ Form.saveFdNewBillProjectPriceDTO.agencyName
                }}</span>
                <span v-else style="color:red;">无</span>
              </el-form-item>
              <el-form-item label="支付类型：" prop="agentSettleStyle">
                <el-select v-model="Form.saveFdNewBillProjectPriceDTO.agentSettleStyle" clearable placeholder="请选择">
                  <el-option v-for="item in agencySettleStyle" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="代理价格：" prop="agentSettleStandard">
                <el-input v-model="Form.saveFdNewBillProjectPriceDTO.agentSettleStandard" @keyup.native="
                  Form.saveFdNewBillProjectPriceDTO.agentSettleStandard = zF.oninput2(
                    Form.saveFdNewBillProjectPriceDTO.agentSettleStandard,
                    2
                  )
                  " placeholder="请输入内容">
                  <template slot="append">
                    <template v-if="Form.saveFdNewBillProjectPriceDTO.agentSettleStyle == '10'">%</template>
                    <template
                      v-else-if="Form.saveFdNewBillProjectPriceDTO.agentSettleStyle == '40' || Form.saveFdNewBillProjectPriceDTO.agentSettleStyle == '70'">元/人/课时</template>
                    <template v-else>元/人</template>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
            <div class="flexca" style="margin: 20px 0">
              <h3>课程信息</h3>
              <p style="margin-left: 20px;">最大考试次数：<el-input-number v-model="paraExamMaxNum" :disabled="paraExamMaxNumDisable" size="small" :controls="false" @change="handleChange" :min="1" :max="999"></el-input-number> 次</p>
            </div>
            <el-table ref="multipleTable" :data="List" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader">
              <el-table-column label="课程名称" align="center" :show-overflow-tooltip="true" prop="courseName" />
              <el-table-column label="课程来源" align="center" :show-overflow-tooltip="true" prop="compName" />
              <el-table-column label="课程总学时" align="center" :show-overflow-tooltip="true" prop="lessonNum" />
              <el-table-column label="要求完成学时" align="center" :show-overflow-tooltip="true" prop="lessonNumDemand"
                width="100" />
              <el-table-column label="必修总学时" align="center" :show-overflow-tooltip="true" prop="lessonNumMust"
                width="100" />
              <el-table-column label="选修总学时" align="center" :show-overflow-tooltip="true" prop="electiveNum"
                width="100" />
              <el-table-column label="视频总时长" align="center" :show-overflow-tooltip="true" prop="kpointTotalDuration"
                width="100">
                <template slot-scope="scope">{{
                  getTime(scope.row.kpointTotalDuration)
                }}</template>
              </el-table-column>
              <el-table-column label="课时时长标准" align="center" :show-overflow-tooltip="true" prop="convertClassHour"
                width="100">
                <template slot-scope="scope">{{
                  scope.row.convertClassHour?$setDictionary('CONVERT_CLASS_HOUE',scope.row.convertClassHour):'45分钟'
                }}</template>
              </el-table-column>
              <el-table-column label="统一考试时间" align="center" width="150">
                <template slot-scope="scope">
                  <span v-if="scope.row.paperStartTime">{{ scope.row.paperStartTime | momentWu }} 至
                    {{ scope.row.paperEndTime | momentWu }}</span>
                  <span v-else>无</span>
                </template>
              </el-table-column>
              <el-table-column label="总课时" align="center" :show-overflow-tooltip="true" prop="convertClassHour" width="100">
                <template slot-scope="{row}">
                  {{(parseInt((row.convertClassHour?row.kpointTotalDuration/(row.convertClassHour*60):row.kpointTotalDuration/(45*60)) * 100) / 100).toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column label="结业考试" align="center" :show-overflow-tooltip="true" prop="paperId">
                <template slot-scope="scope">
                  <span>{{ scope.row.paperId ? "有" : "无" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="260">
                <template slot-scope="scope">
                  <el-button style="padding: 0 5px" type="text" size="mini" @click="
                    see(
                      scope.row.projectCourseId,
                      scope.row.courseId,
                      Form.projectId
                    )
                    ">详情</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" :disabled="Form.projectSource == '80' ||
                    (auditState == '30' && Form.projectSource == '90')
                    " @click="Setexamination(scope.row)" v-if="!scope.row.paperId">设置结业考试</el-button>
                  <el-button v-if="scope.row.paperId" type="text" size="mini" :disabled="Form.projectSource == '80' ||
                    (auditState == '30' && Form.projectSource == '90')
                    " style="padding: 0 5px" @click="Setexamination(scope.row)">查看结业考试</el-button>
                  <el-button v-if="scope.row.paperId" type="text" style="padding: 0px 5px" size="mini"
                    @click="showPaper(scope.row)">结业试卷预览</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="Configurequestionnaire(scope.row, '1')">配置期末问卷</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="Configuredailyquestionnaire(scope.row)">设置每日问卷</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
            <h3 style="margin: 20px 0">课程核算</h3>
            <el-table ref="multipleTable" :data="courseAccountingList" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader">
              <el-table-column label="课程名称" align="center" show-overflow-tooltip prop="courseName" />
              <el-table-column label="课程方名称" align="center" show-overflow-tooltip prop="providerName" />
              <el-table-column label="课件数量" align="center" show-overflow-tooltip prop="kpointNum" />
              <el-table-column label="其中公共课数量" align="center" show-overflow-tooltip prop="publicKpointNum" />
              <el-table-column label="是否核算" align="center" show-overflow-tooltip prop="isAccounting">
                <template slot-scope="{ row }">
                  {{ row.isAccounting ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="数据来源" align="center" show-overflow-tooltip prop="kpointSource" minWidth="120">
                <template slot-scope="scope">{{ pdKpointSource(scope.row.kpointSource) }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="260">
                <template slot-scope="{ row }">
                  <el-button :disabled="!row.contractId" type="text" size="small"
                    @click="previewTheContractHandle(row.contractId)">预览合同</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
            <h3 style="margin: 20px 0">学员信息</h3>
            <el-table ref="multipleTable" :data="studentList" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader">
              <el-table-column label="计划培训人数" align="center" :show-overflow-tooltip="true" prop="planPeople" />
              <el-table-column label="已导入学员人数" align="center" :show-overflow-tooltip="true" prop="actualPeople" />

              <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                  <el-button style="padding: 0 5px" type="text" size="mini"
                    @click="seeStudentInfo(scope.row)">详情</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
            <h3 style="margin: 20px 0">审批记录</h3>
            <el-table ref="multipleTable" :data="ApprovalRecordList" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader">
              <el-table-column label="操作日期" align="center" :show-overflow-tooltip="true" prop="createTime">
                <template slot-scope="scope">{{
                  scope.row.createTime | moment
                }}</template>
              </el-table-column>
              <el-table-column label="操作人" align="center" :show-overflow-tooltip="true" prop="fullname" />
              <el-table-column label="操作内容" align="center" :show-overflow-tooltip="true" prop="auditState">
                <template slot-scope="scope">
                  <span>{{ getAuditName(scope.row.auditState) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审批备注" align="center" :show-overflow-tooltip="true" prop="auditReason" />
              <Empty slot="empty" />
            </el-table>
            <div class="btn-box">
              <el-button class="bgc-bv" @click="back">取 消</el-button>
              <el-button v-if="status != 'three' && currentAuditState != '40'" @click="projectReject" class="bgc-bv"
                :disabled="startStudy">驳 回</el-button>
              <el-button v-if="status != 'second' && currentAuditState != '30'" class="bgc-bv"
                @click="projectPassBefore">通 过</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="审核事由" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>
        <el-input type="textarea" maxlength="100" show-word-limit placeholder="请输入审核理由" v-model="auditReason"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialoglevelVisible" width="30%" :before-close="handleClose" center>
      <div style="display: flex; align-items: center">
        <span style="font-size: 1rem; padding-right: 0.25rem">级别:</span>
        <el-select v-model="level" placeholder="请选择" size="small" style="flex: 1">
          <el-option v-for="item in levelList" :key="item.subsidyLevel" :label="item.subsidyLevel"
            :value="item.subsidyLevel"></el-option>
        </el-select>
      </div>
      <p style="padding: 0.5rem 1rem">请仔细核对级别是否正确。</p>
      <p style="padding: 0rem 1rem">
        如果没有您想要的级别，请去补贴标准里增加。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doCancle" class="bgc-bv">取 消</el-button>
        <el-button @click="doSure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
    <PaperShow ref="PaperShow" :modal="false" />
    <Mytestpaper ref="Mytestpaper" @eventBus="tableBack" @getData="getDataAll" />
    <Questionpaper ref="Questionpaper" @eventBus="tableBack1" @eventBus1="tableBack2" @getData="getDataAll" />
    <!-- 结业考试 -->
    <el-dialog title="结业考试" :visible.sync="dialogExamination" width="600px" top="15%" :center="true"
      :before-close="doClose">
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; align-items: center; margin-bottom: 15px">
          <span>试卷类型：</span>
          <div>
            <el-radio-group v-model="paperSource" size="small">
              <el-radio label="10" border>指定试卷</el-radio>
              <el-radio label="30" border>随机组卷</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div>
          <span>考试名称：</span>
          <span>{{ paperName }}</span>
        </div>
        <div style="padding-top: 15px">
          <span>通过分数：</span>
          <span>{{ paperScore }}分</span>
        </div>
        <div style="padding-top: 15px">
          <span>考试时间：</span>
          <span v-if="paperStartTime">{{ paperStartTime | momentWu }}至{{
            paperEndTime | momentWu
          }}</span>
          <span v-else>未设置考试时间</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-between">
        <div>
          <el-button @click="papercancle">取消</el-button>
        </div>
        <div class="btnBox" v-if="paperId">
          <el-button class="bgc-bv" @click="Examinationedit" :disabled="auditState == '30'">修改</el-button>
          <el-button @click="paperdelete" :disabled="auditState == '30'">删除</el-button>
        </div>
        <div class="btnBox" v-else>
          <el-button class="bgc-bv" @click="addNewTest(paperSource)">新增</el-button>
          <!-- <el-button @click="paperdelete" :disabled="auditState == '30'"
            >删除</el-button
          > -->
        </div>
      </span>
    </el-dialog>
    <!-- 配置每日问卷 -->
    <el-dialog title="配置每日问卷" :visible.sync="dialogExamination1" width="700px" top="3%" :center="true"
      :before-close="doClose1">
      <div style="display: flex; justify-content: flex-end">
        <el-button @click="Configurequestionnaire(row, '2')" class="bgc-bv">设置问卷</el-button>
      </div>
      <div class="ovy-a" style="display: flex; flex-direction: column; height: 480px">
        <el-tree :data="treeData" show-checkbox default-expand-all node-key="kpointId" ref="tree" highlight-current
          @check-change="getChecked" :props="defaultProps">
          <span class="custom-tree-node" slot-scope="{ data }">
            <p class="flexac" v-if="data.level === 1">
              <span style="width: 30px; text-align: left">
                <span class="level" style="margin-right: 10px">
                  <!-- 一级 -->
                  {{ data.chapterNum }}
                </span>
              </span>
              <span>{{ data.catalogName }}</span>
              <span style="margin-left: 50px" v-if="data.paperName">
                问卷名称:{{ data.paperName }}
              </span>
            </p>
            <p class="flexac" v-else>
              <span style="width: 44px; text-align: left">
                <span class="level" style="margin-right: 10px">
                  <!-- 一级 -->
                  {{ data.chapterNum }}
                </span>
              </span>
              <span>{{ data.catalogName }}</span>
              <span style="margin-left: 50px" v-if="data.paperName">
                问卷名称:{{ data.paperName }}
              </span>
            </p>
          </span>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex">
        <div class="btnBox">
          <el-button @click="doClose1">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确认</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog title="预览" :visible.sync="previewLoding1" @close="uploadLodClose" :modal="false" width="50%" top="2%"
      center v-dialogDrag>
      <div class="ovy-a" style="height: 600px">
        <div id="pdf-cert1" style="height: 600px" v-if="fileType == 'pdf' || fileType == 'PDF'"></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <!-- 随机试卷 - 结业考试随机组卷开始 -->
    <el-dialog title="结业考试-随机组卷" :visible.sync="dialogRandom" width="800px" top="2%" v-if="dialogRandom" :center="true"
      @close="dohandleOk">
      <div>
        <el-form ref="ruleForm" label-width="8rem" class="" :rules="rules" :model="ruleForm">
          <el-form-item label="试卷形式:" prop="paperSource">
            <el-radio-group v-model="ruleForm.paperSource" disabled>
              <el-radio label="10">指定试卷</el-radio>
              <el-radio label="30">随机组卷</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="考试名称:" prop="paperName">
            <el-input :disabled="!paperTrue" v-model="ruleForm.paperName" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="题目来源:" prop="sourceValue">
            <el-select v-model="ruleForm.sourceValue" @change="sourceValueEmpty" placeholder="请选择" :disabled="!paperTrue">
              <el-option v-for="item in options" :key="item.index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 题库 -->
          <el-form-item label="请选择题库:" prop="questionBankId" v-if="ruleForm.sourceValue == '10'">
            <el-select :disabled="!paperTrue" v-model="ruleForm.questionBankId" placeholder="请选择"
              @change="questionbankChange" filterable remote :remote-method="getquestionBank">
              <el-option :label="item.questionBankName" :value="item.questionBankId"
                v-for="(item, index) in questionbankList" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <!-- 试卷 -->
          <el-form-item label="请选择试卷:" prop="questionPaperId" v-else>
            <el-select :disabled="!paperTrue" v-model="ruleForm.questionPaperId" placeholder="请选择"
              @change="questionbankChange" filterable remote :remote-method="getquestionPaper">
              <el-option :label="item.paperName" :value="item.paperId" v-for="(item, index) in questionPaperList"
                :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="paperObj">
            <div>
              <p>
                在
                <span style="display: inline-block; width: 50px; text-align: center">{{ choiceNum }}</span>道单选题中随机抽
                <el-input-number v-model="ruleForm.paperObj.SinglechoiceNum" size="small" :precision="0" :min="0"
                  :max="choiceNumMax" :disabled="(!ruleForm.questionBankId && !ruleForm.questionPaperId) ||
                    !paperTrue
                    " style="width: 140px; margin-right: 10px"></el-input-number>道题目，每题
                <el-input-number v-model="ruleForm.paperObj.SinglechoiceScore" size="small" :min="0" :disabled="(!ruleForm.questionBankId && !ruleForm.questionPaperId) ||
                  !paperTrue
                  " style="width: 140px; margin-right: 10px"></el-input-number>分
              </p>
              <p>
                在
                <span style="display: inline-block; width: 50px; text-align: center">
                  {{ multiselectNum }} </span>道多选题中随机抽
                <el-input-number v-model="ruleForm.paperObj.MultiplechoiceNum" size="small" :min="0" :precision="0"
                  :max="multiselectNumMax" :disabled="(!ruleForm.questionBankId && !ruleForm.questionPaperId) ||
                    !paperTrue
                    " style="width: 140px; margin-right: 10px"></el-input-number>道题目，每题
                <el-input-number v-model="ruleForm.paperObj.MultiplechoiceScore" size="small" :min="0" :disabled="(!ruleForm.questionBankId && !ruleForm.questionPaperId) ||
                  !paperTrue
                  " style="width: 140px; margin-right: 10px"></el-input-number>分
              </p>
              <p>
                在
                <span style="display: inline-block; width: 50px; text-align: center">
                  {{ judgeNum }} </span>道判断题中随机抽
                <el-input-number v-model="ruleForm.paperObj.judgeNum" size="small" :min="0" :precision="0"
                  :max="judgeNumMax" :disabled="(!ruleForm.questionBankId && !ruleForm.questionPaperId) ||
                    !paperTrue
                    " style="width: 140px; margin-right: 10px"></el-input-number>道题目，每题
                <el-input-number v-model="ruleForm.paperObj.judgeScore" size="small" :min="0" :disabled="(!ruleForm.questionBankId && !ruleForm.questionPaperId) ||
                  !paperTrue
                  " style="width: 140px; margin-right: 10px"></el-input-number>分
              </p>
              <p>
                在
                <span style="display: inline-block; width: 50px; text-align: center">
                  {{ completionNum }} </span>道填空题中随机抽
                <el-input-number v-model="ruleForm.paperObj.fillNum" size="small" :min="0" :precision="0"
                  :max="completionNumMax" :disabled="(!ruleForm.questionBankId && !ruleForm.questionPaperId) ||
                    !paperTrue
                    " style="width: 140px; margin-right: 10px"></el-input-number>道题目，每题
                <el-input-number v-model="ruleForm.paperObj.fillScore" size="small" :min="0" :disabled="(!ruleForm.questionBankId && !ruleForm.questionPaperId) ||
                  !paperTrue
                  " style="width: 140px; margin-right: 10px"></el-input-number>分
              </p>
            </div>
          </el-form-item>
          <el-form-item label="试卷总分:" prop="paperTotalScore" required>
            <div class="">
              <el-input v-model="ruleForm.paperTotalScore" disabled
                style="width: 200px; margin-right: 10px"></el-input><span>分</span>
            </div>
          </el-form-item>
          <el-form-item label="及格分数:" prop="paperScore">
            <div class="">
              <el-input-number :disabled="!paperTrue" size="small" v-model="ruleForm.paperScore" :min="0" :precision="0"
                style="width: 200px; margin-right: 10px"></el-input-number>
              <span>分</span>
            </div>
          </el-form-item>
          <el-form-item label="统一考试时间:" prop="value2">
            <el-date-picker v-model="ruleForm.value2" type="datetimerange" align="right" start-placeholder="开始日期"
              end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
              size="small"></el-date-picker>
          </el-form-item>
          <p style="color: #d82a41; margin-top: 10px" class="testFomation">
            如果需要学员统一时间进行考试请在此设置，时间可以在班级结束之后。如果设置此字段,请告知学员在安知APP-应用-结业考试里进行考试。
          </p>
        </el-form>
      </div>
      <div class="btn-box flexcc" style="margin-bottom: 10px">
        <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
        <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 结业考试随机组卷结束 - 组卷列表 -->
    <el-dialog title="试卷列表" :visible.sync="dialogRuleExamination" width="800px" top="5%" :center="true"
      @close="doCloseRulePaper">
      <el-table ref="multipleTable" :data="rulePaperList" size="small" tooltip-effect="dark"
        :header-cell-style="tableHeader">
        <el-table-column label="序号" type="index" />
        <el-table-column label="试卷名称" align="center" :show-overflow-tooltip="true" prop="paperName" />
        <el-table-column label="题目数量" align="center" :show-overflow-tooltip="true" prop="questionNum" />
        <el-table-column label="总分数" align="center" :show-overflow-tooltip="true" prop="paperTotalScore" />
        <el-table-column label="通过分数" align="center" :show-overflow-tooltip="true" prop="paperScore" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button style="padding: 0 5px" type="text" size="mini"
              @click="lookRulePaper(scope.row.paperId)">预览试卷</el-button>
          </template>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog title="预览合同" append-to-body :visible.sync="previewTheContractState" v-if="previewTheContractState"
      width="950px" top="2%" center>
      <ContractManagementAdd :seeTH="true" :key="previewTheContractStateId" :seeContractId="previewTheContractStateId"
        v-if="previewTheContractState" />
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import PaperShow from "@/views/resourse/popup/PaperShow";
import Mytestpaper from "@/views/classListCom/classDetailsFile/popup/Mytestpaper";
import Questionpaper from "@/views/AuditManagement/Questionpaper";
import pdf from "pdfobject";
import ContractManagementAdd from "@/views/financialSettlement/contractManagementAdd";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "ClassReviewDetails",
  components: {
    PaperShow,
    Mytestpaper,
    Questionpaper,
    ContractManagementAdd
  },
  mixins: [List],
  data() {
    let checkPaperObj = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      let {
        SinglechoiceNum,
        SinglechoiceScore,
        MultiplechoiceNum,
        MultiplechoiceScore,
        judgeNum,
        judgeScore,
        fillNum,
        fillScore,
      } = value;
      if (SinglechoiceNum + MultiplechoiceNum + judgeNum + fillNum == 0) {
        callback(new Error("请至少选择一项题型题目数量为有效值"));
      } else {
        if (SinglechoiceNum > 0 && SinglechoiceScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (MultiplechoiceNum > 0 && MultiplechoiceScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (judgeNum > 0 && judgeScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (fillNum > 0 && fillScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else {
          callback();
        }
      }
    };
    let passSorceCheck = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      if (value && !reg.test(value)) {
        callback(new Error("及格分数应为大于或等于0的整数"));
      } else if (Number(value) > this.ruleForm.totalScore) {
        callback(new Error("及格分数不能大于总分数"));
      } else if (value == undefined) {
        callback(new Error("请输入及格分数"));
      } else {
        callback();
      }
    };
    return {
      paraExamMaxNum:undefined,//最大考试次数
      paraExamMaxNumDisable:false,//是否可修改最大考试次数
      auditReason: "",
      projectId: JSON.parse(this.$route.query.getClassInfo).projectId,
      ListImg: [],
      Form: {
        projectName: "",
        projectCode: "",
        Trainingtype: "",
        areaNamePath: "",
        deadline: "",
        projectUser: "",
        projectUserPhone: "",
        projectPaperDate: "",
        projectPaperComp: "",
        projectPc: "",
        projectAddress: "",
        projectPeople: "",
        thumbnail: "",
        projectCrowd: "",
        projectSource: "",
        fileKey: "",
        projectClassHourCertificate: "",
        studentCertPrint: "",
        list: [],
        projectRecordno: "",
        recordClasshours: "",
        // 收款&代理 - 价格设置
        saveFdNewBillProjectPriceDTO: {}
      },
      agencySettleStyle: [], //代理商支付类型
      ApprovalRecordList: [],
      List: [],
      status: "first",
      dialogVisible: false,
      currentAuditState: "",
      studentList: [], //学员列表
      // 通过是判断 traintypeid
      dialoglevelVisible: false,
      level: "",
      levelList: [],
      trainTypeId: "", // 通过按钮时用
      dialogExamination: false,
      dialogExamination1: false,
      treeData: [],
      defaultProps: {
        children: "node",
        label: "catalogName",
      },
      kpointIds: [],
      row: {},
      buttonclick: false,
      remindData: [],
      previewLoding1: false,
      ImgSrc: "",
      projectAdvancecharge: "", //班级预扣款金额
      paperSource: "10", //试卷类型
      dialogRandom: false, //随机组卷
      questionbankList: [], //搜索到的试卷选项
      //题目来源选项
      options: [
        {
          value: "10",
          label: "题库",
        },
        {
          value: "20",
          label: "试卷",
        },
      ],
      ruleForm: {
        paperSource: "", //试卷类型
        paperName: "", //试卷名称
        questionBankId: "", //题库Id
        paperScore: "", //通过分数
        paperTotalScore: "", //总分
        // 随机抽题时各题型的参数
        paperObj: {
          SinglechoiceNum: "0", //单选题目数量
          SinglechoiceScore: 0, //单选题目分数
          MultiplechoiceNum: "0", //多选题目数量
          MultiplechoiceScore: 0, //多选题目分数
          judgeNum: "0", //判断题目数量
          judgeScore: 0, //判断题目分数
          fillNum: "0", //填空题目数量
          fillScore: 0, //填空题目分数
        },
        value2: "",
        sourceValue: "", //题目来源
        questionPaperId: "", //试卷Id
      },
      rules: {
        paperName: [{ required: true, message: "请输入试卷名称" }],
        questionBankId: [{ required: true, message: "请选择指定题库" }],
        questionPaperId: [{ required: true, message: "请选择指定试卷" }],
        paperTotalScore: [
          { required: true, message: "请选择试卷或试题计算总分数" },
        ],
        paperScore: [{ required: true, validator: passSorceCheck }],
        paperObj: [
          {
            required: true,
            validator: checkPaperObj,
            trigger: ["blur", "change"],
          },
        ],
        sourceValue: [{ required: true, message: "请选择题目来源" }],
        paymentType: [
          { required: true, message: '请选择收款类型', trigger: 'change' }
        ],
        courseFee: [
          { required: true, message: '请输入课程价格', trigger: 'blur' }
        ],
        platformTechnicalFee: [
          { required: true, message: '请输入技术价格', trigger: 'blur' }
        ],
        platformTotalFee: [
          { required: true, message: '请输入平台服务费', trigger: 'blur' }
        ]
      },
      courseId: "", //课程Id
      choiceNum: 0,
      judgeNum: 0,
      multiselectNum: 0,
      completionNum: 0,
      choiceNumMax: 100,
      judgeNumMax: 100,
      multiselectNumMax: 100,
      completionNumMax: 100,
      questionNumTotal: 0,
      dialogRuleExamination: false, //组卷列表弹窗
      rulePaperList: [],
      questionPaperList: [],
      paperTrue: true,
      agreementList: [],//协议信息
      courseAccountingList: [],//课程核算信息
      previewTheContractState: false,//预览合同
      previewTheContractStateId: '',//预览合同id
    };
  },
  mounted() { },
  created() { },
  watch: {
    // 监听抽题数量变化 把分数清零
    "ruleForm.paperObj.SinglechoiceNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleForm.paperObj.SinglechoiceScore = 0;
        }
        if (val == undefined) {
          this.ruleForm.paperObj.SinglechoiceNum = "0";
        }
      },
    },
    "ruleForm.paperObj.MultiplechoiceNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleForm.paperObj.MultiplechoiceScore = 0;
        }
        if (val == undefined) {
          this.ruleForm.paperObj.MultiplechoiceNum = "0";
        }
      },
    },
    "ruleForm.paperObj.judgeNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleForm.paperObj.judgeScore = 0;
        }
        if (val == undefined) {
          this.ruleForm.paperObj.judgeNum = "0";
        }
      },
    },
    "ruleForm.paperObj.fillNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleForm.paperObj.fillScore = 0;
        }
        if (val == undefined) {
          this.ruleForm.paperObj.fillNum = "0";
        }
      },
    },
    // 计算总分
    "ruleForm.paperObj": {
      handler: function (val) {
        let {
          SinglechoiceNum,
          SinglechoiceScore,
          MultiplechoiceNum,
          MultiplechoiceScore,
          judgeNum,
          judgeScore,
          fillNum,
          fillScore,
        } = this.ruleForm.paperObj;
        this.ruleForm.paperTotalScore = (
          SinglechoiceNum * SinglechoiceScore +
          MultiplechoiceNum * MultiplechoiceScore +
          judgeNum * judgeScore +
          fillNum * fillScore
        ).toFixed(1);
        this.ruleForm.paperScore =
          (SinglechoiceNum * SinglechoiceScore +
            MultiplechoiceNum * MultiplechoiceScore +
            judgeNum * judgeScore +
            fillNum * fillScore) *
          (0.6).toFixed(1);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    })
  },
  methods: {
    // 初始化方法
    init() {
      this.status = JSON.parse(this.$route.query.getClassInfo).status;
      this.currentAuditState = JSON.parse(
        this.$route.query.getClassInfo
      ).currentAuditState;
      this.startStudy = JSON.parse(this.$route.query.getClassInfo).startStudy;
      this.auditState = JSON.parse(this.$route.query.getClassInfo).auditState;
      this.getDatas();
      this.getDataAll();
      this.loadData();
      this.getStudentList();
      this.getlevel(); // 获取等级
      this.getMation();
      this.getAgreementList();
      this.getCourseAccountingList();
      this.getCodeValue();
      this.getPaperLimit()
    },
    //获取码值表 -- 提醒类型
    getCodeValue() {
      const AGENCY_SETTLE_STYLE = this.$setDictionary("AGENCY_SETTLE_STYLE", "list");
      let list = []
      for (const key in AGENCY_SETTLE_STYLE) {
        list.push({
          value: key,
          label: AGENCY_SETTLE_STYLE[key],
        });
      }
      this.agencySettleStyle = list
    },
    // 预览合同弹窗
    previewTheContractHandle(contractId) {
      this.previewTheContractStateId = contractId
      this.previewTheContractState = true
    },
    getAuditName(value) {
      let AuditNameList = [
        {
          value: "10",
          label: "班级创建",
          labelSJZ: "班级创建",
        },
        {
          value: "20",
          label: "提交审核",
          labelSJZ: "提交审核",
        },
        {
          value: "25",
          label: "运营审核通过",
          labelSJZ: "运营审核通过",
        },
        {
          value: "30",
          label: "审核通过",
          labelSJZ: "监管审核通过",
        },
        {
          value: "40",
          label: "审核驳回",
          labelSJZ: "运营审核驳回",
        },
        {
          value: "45",
          label: "",
          labelSJZ: "运营审核驳回",
        },
      ];
      for (let i in AuditNameList) {
        if (AuditNameList[i].value == value) {
          if (this.Form.projectSource == "30") {
            return AuditNameList[i].labelSJZ;
          } else {
            return AuditNameList[i].label;
          }
        }
      }
    },
    // 有效无效协议class
    tableRowClassName({ row, rowIndex }) {
      if (!row.validState) {
        return 'warning-row';
      }
      return '';
    },
    // 获取协议list
    getAgreementList() {
      this.$post("/biz/new/bill/contract/project/list", {
        projectId: this.projectId,
      }).then((ret) => {
        this.agreementList = ret.data || [];
      });
    },
    // 获取协议list
    getCourseAccountingList() {
      this.$post("/run/project/courseAccountingInfo", {
        projectId: this.projectId,
      }).then((ret) => {
        this.courseAccountingList = ret.data || [];
      });
    },
    // 获取班级限制考试次数
    getPaperLimit() {
      this.$post("/biz/project/paperLimit/query", {
        projectId: this.projectId,
      }).then((res) => {
        let resData = res?.data?.paraExamMaxNum ?? undefined
        if(resData){
          this.paraExamMaxNumDisable = true
        }else{
          this.paraExamMaxNumDisable = false
        }
        this.paraExamMaxNum = resData
      });
    },
    // 修改班级限制考试次数
    handleChange(paraExamMaxNum){
      this.$post("/biz/project/paperLimit/save", {projectId: this.projectId,paraExamMaxNum}).then((res) => {});
    },
    // 判断:课程核算设置-数据来源的显示字段名称
    pdKpointSource(row) {
      if (row) {
        let arr = row.split(',')
        let str = "";
        for (let i = 0; i < arr.length; i++) {
          str += this.$setDictionary("KPOINT_SOURCE", arr[i]) + ",";
        }
        return str.slice(0, -1)
      }
      // this.$setDictionary("KPOINT_SOURCE", scope.row.kpointSource)
      // 北京晟融机构id;10951，10982 联安众id;的显示数据来源列
      // let compIdArr = ["10981", "10951", "10982"]
      // return compIdArr.includes(this.userJson.compId)
    },
    getMation() {
      this.$post("/biz/reviewRemind/queryReviewRemind", {
        projectId: this.projectId,
        remindMode: "10",
      }).then((ret) => {
        this.remindData = ret.data;
      });
    },
    // 等级
    getlevel() {
      this.$post("/biz/new/subsidy/standard/projectSubsidyList", {
        projectId: this.projectId,
      }).then((ret) => {
        this.levelList = ret.data;
      });
    },
    doEdit() {
      let path = "/web/Createcourse";
      this.$router.replace({
        path,
        query: {
          courseId: this.courseId,
        },
      });
    },
    getStudentList() {
      this.$post("/run/project/projectStudentInfo", {
        projectId: this.projectId,
      })
        .then((ret) => {
          if (ret.status == "0") {
            let obj = {};
            if (ret.data.planPeople) {
              obj.planPeople = ret.data.planPeople;
            }
            if (ret.data.actualPeople) {
              obj.actualPeople = ret.data.actualPeople;
            }
            this.studentList.push(obj);
          }
        })
        .catch((err) => {
          return;
        });
    },
    seeStudentInfo(row) {
      this.$router.push({
        path: "/web/AuditManagement/studentDetail",
        query: {
          projectId: this.projectId,
          projectName: this.Form.projectName,
          projectCode: this.Form.projectCode,
        },
      });
    },
    // 获取班级审核详情
    loadData() {
      this.$post("/biz/project/query", { projectId: this.projectId })
        .then((ret) => {
          let resData = ret.data;
          this.Form = {
            advancechargeBalance: resData.advancechargeBalance,
            projectId: resData.projectId,
            areaId: resData.areaId,
            projectName: resData.projectName,
            projectCode: resData.projectCode,
            areaNamePath: resData.areaNamePath,
            street: resData.street,
            startDate: resData.startDate,
            endDate: resData.endDate,
            deadDate: resData.signEndDate,
            projectUser: resData.projectUser,
            projectUserPhone: resData.projectUserPhone,
            projectPaperDate: resData.projectPaperDate,
            projectPaperComp: resData.projectPaperComp,
            projectPc: resData.projectPc,
            projectAddress: resData.projectAddress,
            projectPeople: resData.projectPeople,
            thumbnail: resData.applicationFormUrl,
            projectCrowd: resData.projectCrowd,
            fileKey: resData.applicationForm,
            projectClassHourCertificate: resData.projectClassHourCertificate,
            studentCertPrint: resData.studentCertPrint,
            projectSource: resData.projectSource,
            enableSurvey: resData.enableSurvey,
            spotCheckNum: resData.spotCheckNum,
            trainTypeId: resData.trainTypeId,
            subsidyLevel: resData.subsidyLevel,
            belongHebei: resData.belongHebei,
            trainMethod: resData.trainMethod,
            paraExamLimitMinTime: resData.paraExamLimitMinTime || '',
            list: resData.list || [],
            originalParameter:
              (resData.originalParameter.areaId == "-1000"
                ? "默认"
                : resData.originalParameter.areaNamePath) +
              "-" +
              (resData.originalParameter.trainTypeId == "-1000"
                ? "默认"
                : resData.originalParameter.trainTypeNamePath),
            originalParameters: resData.originalParameter,
            projectRecordno: resData.projectRecordno,
            recordClasshours: resData.recordClasshours,
            isSubsidy: resData.isSubsidy,
            saveFdNewBillProjectPriceDTO: { ...resData.saveFdNewBillProjectPriceDTO }, // 价格设置：收款&代理
          };
          if (!this.Form.saveFdNewBillProjectPriceDTO.paymentType) {
            this.$set(this.Form.saveFdNewBillProjectPriceDTO, 'paymentType', "");
          }
          if (!this.Form.saveFdNewBillProjectPriceDTO.platformTechnicalFee) {
            this.$set(this.Form.saveFdNewBillProjectPriceDTO, 'platformTechnicalFee', "");
          }
          if (!this.Form.saveFdNewBillProjectPriceDTO.courseFee) {
            this.$set(this.Form.saveFdNewBillProjectPriceDTO, 'courseFee', "");
          }
          if (!this.Form.saveFdNewBillProjectPriceDTO.platformTotalFee) {
            this.$set(this.Form.saveFdNewBillProjectPriceDTO, 'platformTotalFee', "");
          }
          if (!this.Form.saveFdNewBillProjectPriceDTO.agentSettleStyle) {
            this.$set(this.Form.saveFdNewBillProjectPriceDTO, 'agentSettleStyle', "");
          }
          if (!this.Form.saveFdNewBillProjectPriceDTO.agentSettleStandard) {
            this.$set(this.Form.saveFdNewBillProjectPriceDTO, 'agentSettleStandard', "");
          }
          console.log(this.Form.saveFdNewBillProjectPriceDTO)
          let areaNamePath = this.Form.areaNamePath.replace(/,/g, "-");
          this.Form.areaNamePath = areaNamePath;
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (resData.codeBo.trainTypeNamePath) {
            name1 = resData.codeBo.trainTypeNamePath;
          }
          if (resData.codeBo.postName) {
            name2 = "/" + resData.codeBo.postName;
          }
          if (resData.codeBo.industryNamePath) {
            name3 = "/" + resData.codeBo.industryNamePath;
          }
          if (resData.codeBo.occupationNamePath) {
            name4 = "/" + resData.codeBo.occupationNamePath;
          }
          if (resData.codeBo.trainLevelName) {
            name5 = "/" + resData.codeBo.trainLevelName;
          }
          let name = name1 + name2 + name3 + name4 + name5;
          name = name.replace(/,/g, "-");
          this.Form.Trainingtype = name;
          this.ListImg.push(this.Form.thumbnail);
          if (this.Form.advancechargeBalance) {
            this.getAdvanceMoney(this.projectId);
          }
        })
        .catch((err) => {
          return;
        });
    },
    getAdvanceMoney(projectId) {
      this.$post("/biz/advancecharge/classAuditQueryAdvancecharge", {
        projectId,
      })
        .then((ret) => {
          this.projectAdvancecharge = ret.data.projectAdvancecharge;
        })
        .catch((err) => {
          return;
        });
    },
    see(projectCourseId, courseId, projectId) {
      this.$router.push({
        path:
          this.Form.projectSource != "30"
            ? "/web/SupervisionEnd/classJgCourse"
            : "/web/classdetailtabs",
        query: {
          projectCourseId,
          courseId,
          projectId,
          stu: "view",
          projectSource: this.Form.projectSource,
        },
      });
    },
    getDatas() {
      this.$post("/os/auditLog/queryLogInfoByType", {
        auditObjectId: this.projectId,
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.ApprovalRecordList = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    getDataAll() {
      this.$post("/biz/projectCourse/queryProjectCourseDetail", {
        projectId: this.projectId,
      })
        .then((ret) => {
          if (ret.status == "0") {
            this.List = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 等级弹窗关闭
    doCancle() {
      this.dialoglevelVisible = false;
      this.level = "";
    },
    // 有等级时 确定
    doSure() {
      if (!this.level) {
        this.$message.error("请选择级别");
      } else {
        this.projectPass();
      }
    },
    // 通过 - 前校验
    projectPassBefore() {
      // 校验价格设置
      this.$refs['priceSet'].validate(async (valid) => {
        if (valid) {
          const VALIDCONTRACT = await this.$post("/biz/new/bill/contract/project/exist/validContract", { projectId: this.projectId })
          if (VALIDCONTRACT.data) {
            const CONFIRM = await this.$confirm("提示", {
              title: "提示",
              message: VALIDCONTRACT.data,
              showCancelButton: true,
              confirmButtonText: "取消",
              cancelButtonText: "确定",
              closeOnClickModal: false,
              closeOnPressEscape: false,
              distinguishCancelAndClose: true,
              confirmButtonClass: "confirmButtonClass",
            })
              .then(() => { return false; })
              .catch((action) => {
                if (action == "cancel") {
                  return true;
                }
              })
            if (!CONFIRM) return
          }
          this.$post("/biz/reviewRemind/queryReviewRemind", {
            projectId: this.projectId,
            remindMode: "20",
          }).then((ret) => {
            if (ret.data.length != 0) {
              let newDatas = [];
              const h = this.$createElement;
              for (const i in ret.data) {
                newDatas.push(
                  h("p", null, Number(i) + 1 + "." + ret.data[i].remindText)
                );
              }
              this.$confirm("提示", {
                title: "提示",
                message: h("div", null, newDatas),
                showCancelButton: true,
                confirmButtonText: "取消",
                cancelButtonText: "确定",
                closeOnClickModal: false,
                closeOnPressEscape: false,
                distinguishCancelAndClose: true,
                confirmButtonClass: "confirmButtonClass",
              })
                .then(() => { })
                .catch((action) => {
                  if (action == "cancel") {
                    if (this.Form.originalParameter == "默认-默认") {
                      this.$confirm(
                        "该地区没有配置课程参数，将采用默认参数",
                        "提示",
                        {
                          showCancelButton: true,
                          confirmButtonText: "取消",
                          cancelButtonText: "确定",
                          closeOnClickModal: false,
                          closeOnPressEscape: false,
                          confirmButtonClass: "confirmButtonClass",
                          distinguishCancelAndClose: true,
                        }
                      )
                        .then(() => { })
                        .catch((action1) => {
                          if (action1 == "cancel") {
                            this.$post("/run/project/passFrontAdvanceStandard", {
                              projectId: this.projectId,
                            }).then((res) => {
                              if (res.status == "0") {
                                if (res.message == "操作成功！") {
                                  this.projectPass();
                                } else {
                                  this.$confirm(res.message, "审核申请", {
                                    confirmButtonText: "取消",
                                    cancelButtonText: "确定",
                                    confirmButtonClass: "confirmButtonClass",
                                  })
                                    .then(() => {
                                      return;
                                    })
                                    .catch(() => {
                                      this.projectPass();
                                    });
                                }
                              }
                            });
                          } else {
                            return;
                          }
                        });
                    } else {
                      this.$post("/run/project/passFrontAdvanceStandard", {
                        projectId: this.projectId,
                      }).then((res) => {
                        if (res.status == "0") {
                          if (res.message == "操作成功！") {
                            this.projectPass();
                          } else {
                            this.$confirm(res.message, "审核申请", {
                              confirmButtonText: "取消",
                              cancelButtonText: "确定",
                              confirmButtonClass: "confirmButtonClass",
                            })
                              .then(() => {
                                return;
                              })
                              .catch(() => {
                                this.projectPass();
                              });
                          }
                        }
                      });
                    }
                  } else {
                    return;
                  }
                });
            } else {
              if (this.Form.originalParameter == "默认-默认") {
                this.$confirm("该地区没有配置课程参数，将采用默认参数", "提示", {
                  showCancelButton: true,
                  confirmButtonText: "取消",
                  cancelButtonText: "确定",
                  closeOnClickModal: false,
                  closeOnPressEscape: false,
                  confirmButtonClass: "confirmButtonClass",
                  distinguishCancelAndClose: true,
                })
                  .then(() => { })
                  .catch((action) => {
                    if (action == "cancel") {
                      this.$post("/run/project/passFrontAdvanceStandard", {
                        projectId: this.projectId,
                      }).then((res) => {
                        if (res.status == "0") {
                          if (res.message == "操作成功！") {
                            this.projectPass();
                          } else {
                            this.$confirm(res.message, "审核申请", {
                              confirmButtonText: "取消",
                              cancelButtonText: "确定",
                              confirmButtonClass: "confirmButtonClass",
                            })
                              .then(() => {
                                return;
                              })
                              .catch(() => {
                                this.projectPass();
                              });
                          }
                        }
                      });
                    } else {
                      return;
                    }
                  });
              } else {
                this.$post("/run/project/passFrontAdvanceStandard", {
                  projectId: this.projectId,
                }).then((res) => {
                  if (res.status == "0") {
                    if (res.message == "操作成功！") {
                      this.projectPass();
                    } else {
                      this.$confirm(res.message, "审核申请", {
                        confirmButtonText: "取消",
                        cancelButtonText: "确定",
                        confirmButtonClass: "confirmButtonClass",
                      })
                        .then(() => {
                          return;
                        })
                        .catch(() => {
                          this.projectPass();
                        });
                    }
                  }
                });
              }
            }
          });
        }
      });
    },
    // 通过
    projectPass() {
      if (this.Form.projectSource == "20") {
        this.$confirm(
          "外部班级审核通过之后不可以在驳回,该班级确定审核通过吗？",
          "审核申请",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
          }
        )
          .then(() => {
            this.$post("/interface/cCSkillsTraing/openClassSure", {
              projectId: this.Form.projectId,
            })
              .then((ret) => {
                if (ret.status == "0") {
                  this.$message({
                    type: "success",
                    message: "成功",
                  });
                  setTimeout(() => {
                    this.$router.push({
                      path: "/web/ClassReview",
                      query: {
                        active: this.status ? this.status : "second",
                        refresh: true,
                      },
                    });
                  }, 3000);
                }
              })
              .catch((err) => {
                return;
              });
          })
          .catch(() => {
            return;
          });
        //郑州班级的验证临时关闭 -- 40
      } else if (this.Form.projectSource == "40") {
        this.$post("/biz/heNan/pushProject/pushClassInfoApproval", {
          projectId: this.Form.projectId,
        })
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                type: "success",
                message: "成功",
              });
              setTimeout(() => {
                this.$router.push({
                  path: "/web/ClassReview",
                  query: {
                    active: this.status ? this.status : "second",
                    refresh: true,
                  },
                });
              }, 3000);
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        let parmar = {
          resourceId: this.projectId,
          auditState: "30",
          currentAuditState: this.currentAuditState,
        };
        // 班级id
        this.Form.saveFdNewBillProjectPriceDTO.projectId = this.projectId;
        // 是否收款
        this.Form.saveFdNewBillProjectPriceDTO.hasSetPayeePrice = true;
        // 代理价格 - 是否有代理商
        if (this.Form.saveFdNewBillProjectPriceDTO.agencyName) {
          if (this.Form.saveFdNewBillProjectPriceDTO.agentSettleStyle && this.Form.saveFdNewBillProjectPriceDTO.agentSettleStandard) {
            this.Form.saveFdNewBillProjectPriceDTO.hasSetAgentPrice = true;
          } else if (!this.Form.saveFdNewBillProjectPriceDTO.agentSettleStyle && !this.Form.saveFdNewBillProjectPriceDTO.agentSettleStandard) {
            this.Form.saveFdNewBillProjectPriceDTO.hasSetAgentPrice = false;
          } else {
            this.$message.error('代理价格填写不完整，请补全');
            return false;
          }
        } else {
          this.Form.saveFdNewBillProjectPriceDTO.hasSetAgentPrice = false;
        }
        // 收款价格 - 支付类型
        if (this.Form.saveFdNewBillProjectPriceDTO.paymentType == '10') {
          this.Form.saveFdNewBillProjectPriceDTO.platformTotalFee = '';
        }

        if (this.Form.saveFdNewBillProjectPriceDTO.paymentType == '20') {
          this.Form.saveFdNewBillProjectPriceDTO.courseFee = '';
          this.Form.saveFdNewBillProjectPriceDTO.platformTechnicalFee = '';
        }
        parmar.saveFdNewBillProjectPriceDTO = this.Form.saveFdNewBillProjectPriceDTO;
        if (this.Form.trainTypeId == "5" || this.Form.trainTypeId == "16") {
          parmar.subsidyLevel = this.level;
        }
        parmar.paraExamMaxNum = this.paraExamMaxNum;
        this.$post("/run/project/modifyAuditPassState", parmar)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                type: "success",
                message: "成功",
              });
              setTimeout(() => {
                this.$router.push({
                  path: "/web/ClassReview",
                  query: {
                    active: this.status ? this.status : "second",
                    refresh: true,
                  },
                });
              }, 3000);
            }
          })
          .catch((err) => {
            return;
          });
      }
    },
    // 驳回
    projectReject() {
      this.dialogVisible = true;
    },
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        resourceId: this.projectId,
        auditReason: this.auditReason,
        auditState: "40",
        currentAuditState: this.currentAuditState,
      };
      this.$post("/run/project/modifyAuditRejectState", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              type: "success",
              message: "成功",
            });
            setTimeout(() => {
              this.$router.push({
                path: "/web/ClassReview",
                query: {
                  active: this.status ? this.status : "three",
                },
              });
            }, 3000);
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    },
    cancle() {
      this.auditReason = "";
      this.dialogVisible = false;
    },
    showPaper(row) {
      if (row.paperSource == "10") {
        this.$refs.PaperShow.showPopUp(row.paperId, row.projectCourseId); // 打开弹窗预览试题
      } else {
        this.dialogRuleExamination = true;
        this.getRulePaperList(row.projectCoursePaperRuleId);
      }
    },
    getRulePaperList(projectCoursePaperRuleId) {
      this.$post("/biz/paper/getPaperInfoRandomList", {
        projectCoursePaperRuleId,
      }).then((ret) => {
        this.rulePaperList = ret.data || [];
      });
    },
    /* 组卷预览试卷 */
    lookRulePaper(paperId) {
      this.$refs.PaperShow.showPopUp(paperId); // 打开弹窗预览试题
    },
    //关闭组卷列表
    doCloseRulePaper() {
      this.dialogRuleExamination = false;
      this.rulePaperList = [];
    },
    back() {
      this.$router.push({
        path: "/web/ClassReview",
        query: {
          active: this.status ? this.status : "first",
          // name:
          //   this.status == "first"
          //     ? "first"
          //     : this.status == "second"
          //     ? "second"
          //     : "three"
        },
      });
    },
    goHerf() {
      this.$router.push({
        path: "/web/ClassReview",
        query: {
          active: this.status ? this.status : "first",
          // name:
          //   this.status == "first"
          //     ? "first"
          //     : this.status == "second"
          //     ? "second"
          //     : "three"
        },
      });
      sessionStorage.setItem("radio", "");
    },
    tableBack() {
      this.dialogExamination = false;
      this.getDataAll();
    },
    // 设置课后考试
    Setexamination(row) {
      this.dialogExamination = true;
      this.paperId = row.paperId;
      this.courseId = row.courseId;
      this.projectCourseId = row.projectCourseId;
      this.projectCoursePaperRuleId = row.projectCoursePaperRuleId;
      this.paperScore = row.paperScore;
      this.paperSource = row.paperSource;
      this.paperName = row.paperName;
      this.paperStartTime = row.paperStartTime;
      this.paperEndTime = row.paperEndTime;
      this.projectCourseId = row.projectCourseId;
      this.paperTotalScore = row.paperTotalScore;
    },
    //新增课后考试试卷
    addNewTest(paperSource) {
      this.$post(
        "/run/project/course/paperRule/setProjectCourseFinalTestPaperType",
        { paperSource, projectCourseId: this.projectCourseId }
      ).then((res) => {
        if (res.status == 0) {
          if (paperSource == "10") {
            this.$refs.Mytestpaper.showPopUp(
              this.paperTotalScore,
              this.projectCourseId,
              this.paperScore,
              this.paperName,
              this.paperId,
              this.paperStartTime,
              this.paperEndTime
            );
          } else {
            this.dialogRandom = true;
            this.ruleForm.paperSource = paperSource;
          }
        }
      });
    },
    //删除结业考试
    paperdelete() {
      this.$confirm("是否删除结业考试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "confirmButtonClass",
        type: "warning",
      })
        .then(() => {
          if (this.paperSource == "10") {
            this.$post("/biz/projectCourse/deleteProjectCoursePaper", {
              projectCourseId: this.projectCourseId,
            })
              .then((ret) => {
                this.dialogExamination = false;
                this.$message({
                  message: ret.message,
                  type: "success",
                });
                this.getDataAll();
              })
              .catch((err) => {
                return;
              });
          } else {
            this.$post(
              "/run/project/course/paperRule/deleteProjectCourseFinalTestRandomRule",
              {
                projectCourseId: this.projectCourseId,
                projectCoursePaperRuleId: this.projectCoursePaperRuleId,
              }
            )
              .then((ret) => {
                this.dialogExamination = false;
                this.$message({
                  message: ret.message,
                  type: "success",
                });
                this.getDataAll();
              })
              .catch((err) => {
                return;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //修改结业考试
    Examinationedit() {
      if (this.paperSource == "10") {
        this.$refs.Mytestpaper.showPopUp(
          this.paperTotalScore,
          this.projectCourseId,
          this.paperScore,
          this.paperName,
          this.paperId,
          this.paperStartTime,
          this.paperEndTime
        );
      } else {
        this.dialogRandom = true;
        this.ruleForm.paperSource = "30";
        this.paperTrue = true;
        if (this.projectCoursePaperRuleId) {
          this.$post(
            "/run/project/course/paperRule/selectProjectCourseFinalTestPaperType",
            { projectCoursePaperRuleId: this.projectCoursePaperRuleId }
          ).then((ret) => {
            this.ruleForm = {
              // 题目来源
              sourceValue: ret.data.eduProjectCoursePaperRule.questionSource,
              paperSource: "30",
              paperName: ret.data.eduProjectCoursePaperRule.paperName,
              paperTotalScore:
                ret.data.eduProjectCoursePaperRule.paperTotalScore,
              paperScore: ret.data.eduProjectCoursePaperRule.paperScore,
              paperObj: {
                SinglechoiceNum:
                  ret.data.eduProjectCoursePaperRule.singleChoiceNum,
                SinglechoiceScore:
                  ret.data.eduProjectCoursePaperRule.singleChoiceScore,
                MultiplechoiceNum:
                  ret.data.eduProjectCoursePaperRule.multipleChoiceNum,
                MultiplechoiceScore:
                  ret.data.eduProjectCoursePaperRule.multipleChoiceScore,
                judgeNum: ret.data.eduProjectCoursePaperRule.judgeNum,
                judgeScore: ret.data.eduProjectCoursePaperRule.judgeScore,
                fillNum: ret.data.eduProjectCoursePaperRule.fillNum,
                fillScore: ret.data.eduProjectCoursePaperRule.fillScore,
              },
              value2: [
                ret.data.eduProjectCoursePaperRule.paperStartTime
                  ? ret.data.eduProjectCoursePaperRule.paperStartTime.replaceAll(
                    "/",
                    "-"
                  )
                  : "",
                ret.data.eduProjectCoursePaperRule.paperEndTime
                  ? ret.data.eduProjectCoursePaperRule.paperEndTime.replaceAll(
                    "/",
                    "-"
                  )
                  : "",
              ],
            };
            if (ret.data.eduProjectCoursePaperRule.questionSource == "10") {
              this.choiceNum = ret.data.queryQBbankBO.choiceNum;
              this.judgeNum = ret.data.queryQBbankBO.judgeNum;
              this.multiselectNum = ret.data.queryQBbankBO.multiselectNum;
              this.completionNum = ret.data.queryQBbankBO.completionNum;
              this.choiceNumMax = ret.data.queryQBbankBO.choiceNum;
              this.judgeNumMax = ret.data.queryQBbankBO.judgeNum;
              this.multiselectNumMax = ret.data.queryQBbankBO.multiselectNum;
              this.completionNumMax = ret.data.queryQBbankBO.completionNum;
              this.ruleForm.questionBankId =
                ret.data.eduProjectCoursePaperRule.questionBankId;
            } else {
              this.choiceNum = ret.data.queryQuestionSourcePaperBO.choiceNum;
              this.judgeNum = ret.data.queryQuestionSourcePaperBO.judgeNum;
              this.multiselectNum =
                ret.data.queryQuestionSourcePaperBO.multiselectNum;
              this.completionNum =
                ret.data.queryQuestionSourcePaperBO.completionNum;
              this.choiceNumMax = ret.data.queryQuestionSourcePaperBO.choiceNum;
              this.judgeNumMax = ret.data.queryQuestionSourcePaperBO.judgeNum;
              this.multiselectNumMax =
                ret.data.queryQuestionSourcePaperBO.multiselectNum;
              this.completionNumMax =
                ret.data.queryQuestionSourcePaperBO.completionNum;
              this.ruleForm.questionPaperId =
                ret.data.eduProjectCoursePaperRule.questionSourcePaperId;
            }

            if (ret.data.eduProjectCoursePaperRule.questionSource == "10") {
              this.getEcho(
                ret.data.queryQBbankBO.questionBankName,
                ret.data.eduProjectCoursePaperRule.questionBankId
              );
            }
            if (ret.data.eduProjectCoursePaperRule.questionSource == "20") {
              this.getTestPaper(
                ret.data.queryQuestionSourcePaperBO.paperName,
                ret.data.eduProjectCoursePaperRule.questionSourcePaperId
              );
            }
          });
          this.$post("/run/project/course/paperRule/selectPaperUser", {
            projectCoursePaperRuleId: this.projectCoursePaperRuleId,
          }).then((res) => {
            this.paperTrue = res.data;
          });
        }
      }
    },
    //获得试卷反查

    getTestPaper(paperName, paperId) {
      this.$post("/run/project/course/paperRule/queryQuestionSourcePaper", {
        paperName,
      }).then((res) => {
        if (res.status == 0) {
          this.questionPaperList = res.data;
          // this.ruleForm.questionPaperId = paperId;
        }
      });
    },
    //题库反查
    getEcho(questionBankName, questionBankId) {
      this.$post("/run/project/course/paperRule/queryQBbank", {
        questionBankName,
      }).then((res) => {
        if (res.status == 0) {
          this.questionbankList = res.data;
          this.ruleForm.questionBankId = questionBankId;
        }
      });
    },
    //取消
    papercancle() {
      this.dialogExamination = false;
    },
    canclepaper() {
      this.dialogExamination = false;
    },
    doClose() {
      this.dialogExamination = false;
    },
    //配置期末考试
    Configurequestionnaire(row, stu) {
      this.radio = sessionStorage.getItem("radio");
      this.buttonclick = true;
      this.$refs.Questionpaper.showPopUp(row, stu, this.radio);
    },
    Configuredailyquestionnaire(row) {
      this.dialogExamination1 = true;
      this.projectCourseId = row.projectCourseId;
      this.row = row;
      this.getTer(row);
    },
    getTer(row) {
      this.$post("/course/survey/config/questionnaire", {
        projectCourseId: row.projectCourseId,
      }).then((ret) => {
        // this.treeData = ret.data;
        this.mapData(ret.data);
      });
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        if (item.node) {
          item.node.forEach((el, k) => {
            el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
          });
        }
      });

      this.$nextTick(() => {
        this.treeData = data;
      });
    },
    getChecked(data, checked, indeterminate) {
      let res = this.$refs.tree.getCheckedNodes();
      this.kpointIds = [];
      res.forEach((element) => {
        this.kpointIds.push(element.kpointId);
      });
    },
    tableBack1(data) {
      this.radio = data;
    },
    tableBack2(data) {
      this.buttonclick = data;
    },
    doClose1() {
      this.dialogExamination1 = false;
      this.kpointIds = [];
      this.buttonclick = false;
    },
    doOk() {
      if (this.buttonclick) {
        const parmar = {
          paperId: this.radio,
          projectCourseId: this.row.projectCourseId,
          kpointIds: this.kpointIds,
          // paperStartTime:'',
          // paperEndTime:''
        };
        if (this.radio) {
          sessionStorage.setItem("radio", this.radio);
        } else {
          sessionStorage.setItem("radio", "");
        }

        this.$post("/course/survey/config/every-day", parmar)
          .then((ret) => {
            this.$message({
              message: "配置每日问卷成功",
              type: "success",
            });
            this.getTer(this.row);
            this.buttonclick = false;
            // this.doClose1();
            // this.$emit("eventBus");
            // this.$emit("getData");
            // this.$emit("getDatasall");
          })
          .then((err) => {
            return;
          });
      } else {
        this.$message({
          message: "请先设置问卷",
          type: "error",
        });
      }
    },
    //预览
    lookExl(item) {
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    // 指定题库  //获取指定题库或指定试卷
    getquestionBank(query) {
      if (query) {
        this.$post("/run/project/course/paperRule/queryQBbank", {
          questionBankName: query,
        }).then((res) => {
          this.questionbankList = res.data || [];
        });
      }
    },
    getquestionPaper(query) {
      if (query) {
        this.$post("/run/project/course/paperRule/queryQuestionSourcePaper", {
          paperName: query,
        }).then((res) => {
          this.questionPaperList = res.data || [];
        });
      }
    },
    //随机试卷切换题目来源清空
    sourceValueEmpty(val) {
      if (val == "10") {
        this.ruleForm.questionPaperId = "";
      } else {
        this.ruleForm.questionBankId = "";
      }
      this.ruleForm.paperObj = {
        SinglechoiceNum: "0", //单选题目数量
        SinglechoiceScore: 0, //单选题目分数
        MultiplechoiceNum: "0", //多选题目数量
        MultiplechoiceScore: 0, //多选题目分数
        judgeNum: "0", //判断题目数量
        judgeScore: 0, //判断题目分数
        fillNum: "0", //填空题目数量
        fillScore: 0, //填空题目分数
      };
      this.choiceNum = 0;
      this.judgeNum = 0;
      this.multiselectNum = 0;
      this.completionNum = 0;
    },
    // 设置结业考试随机组卷  题目详细信息
    questionbankChange(val) {
      let item = {};
      if (this.ruleForm.sourceValue == "10") {
        item = this.questionbankList.find((el) => el.questionBankId == val);
      } else if (this.ruleForm.sourceValue == "20") {
        item = this.questionPaperList.find((el) => el.paperId == val);
      }

      this.choiceNum = item.choiceNum;
      this.judgeNum = item.judgeNum;
      this.multiselectNum = item.multiselectNum;
      this.completionNum = item.completionNum;
      this.choiceNumMax = item.choiceNum;
      this.judgeNumMax = item.judgeNum;
      this.multiselectNumMax = item.multiselectNum;
      this.completionNumMax = item.completionNum;
      //清空选择题目数量和分数
      this.ruleForm.paperObj = {
        SinglechoiceNum: "0", //单选题目数量
        SinglechoiceScore: 0, //单选题目分数
        MultiplechoiceNum: "0", //多选题目数量
        MultiplechoiceScore: 0, //多选题目分数
        judgeNum: "0", //判断题目数量
        judgeScore: 0, //判断题目分数
        fillNum: "0", //填空题目数量
        fillScore: 0, //填空题目分数
      };
      // this.questionbankList = [];
      // this.$post("/run/project/course/paperRule/queryQBbank", {
      //   questionBankId: val,
      // }).then((res) => {
      //   // ;
      //   this.questionbankList = res.data;
      //   let item = this.questionbankList.find((el) => el.questionBankId == val);
      //   this.choiceNum = item.choiceNum;
      //   this.judgeNum = item.judgeNum;
      //   this.multiselectNum = item.multiselectNum;
      //   this.completionNum = item.completionNum;
      //   this.choiceNumMax = item.choiceNum;
      //   this.judgeNumMax = item.judgeNum;
      //   this.multiselectNumMax = item.multiselectNum;
      //   this.completionNumMax = item.completionNum;
      // });
    },
    getParams() {
      let params = {
        paperName: this.ruleForm.paperName, // 考试名称
        paperTotalScore: this.ruleForm.paperTotalScore, //总分
        paperScore: this.ruleForm.paperScore, // 通过分数
        projectCourseId: this.projectCourseId,
        projectId: this.projectId,
        courseId: this.courseId,
        singleChoiceScore: this.ruleForm.paperObj.SinglechoiceScore, //单选
        singleChoiceNum: this.ruleForm.paperObj.SinglechoiceNum, //单选
        multipleChoiceScore: this.ruleForm.paperObj.MultiplechoiceScore, //多选
        multipleChoiceNum: this.ruleForm.paperObj.MultiplechoiceNum, //多选
        judgeScore: this.ruleForm.paperObj.judgeScore, //判断
        judgeNum: this.ruleForm.paperObj.judgeNum, //判断
        fillScore: this.ruleForm.paperObj.fillScore, //填空
        fillNum: this.ruleForm.paperObj.fillNum, //填空
        questionSource: this.ruleForm.sourceValue, //试卷来源  10代表题库  20代表试卷
      };
      if (this.ruleForm.value2) {
        (params.paperStartTime = this.ruleForm.value2[0]),
          (params.paperEndTime = this.ruleForm.value2[1]);
      }
      if (this.projectCoursePaperRuleId) {
        params.projectCoursePaperRuleId = this.projectCoursePaperRuleId;
      }
      if (this.ruleForm.sourceValue == "10") {
        params.questionBankId = this.ruleForm.questionBankId;
      } else if (this.ruleForm.sourceValue == "20") {
        params.questionSourcePaperId = this.ruleForm.questionPaperId;
      } else {
        this.$message.error("报错");
        return false;
      }
      return params;
    },
    // 新增
    doAddSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //判断分数是否不等于100
          if (
            Number(this.ruleForm.paperTotalScore) > 100 ||
            Number(this.ruleForm.paperTotalScore) < 100
          ) {
            this.$confirm(
              "您设置的试卷总分为" +
              '<span style="color:red;font-size:26px">' +
              this.ruleForm.paperTotalScore +
              "</span>" +
              "分,确认使用?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                dangerouslyUseHTMLString: true,
                closeOnClickModal: false,
              }
            )
              .then(() => {
                this.randomAjax()
              })
              .catch(() => {
                return;
              });
          } else {
            this.randomAjax()
          }
        } else {
          this.$message({
            type: "warning",
            message: "请检查必填项",
          });
        }
      });
    },
    //设置随机试卷确定接口
    randomAjax() {
      if (!this.paperTrue) {
        let param = {
          projectCoursePaperRuleId: this.projectCoursePaperRuleId,
          projectCourseId: this.projectCourseId,
        };
        if (this.ruleForm.value2) {
          (param.paperStartTime = this.ruleForm.value2[0]),
            (param.paperEndTime = this.ruleForm.value2[1]);
        }
        this.$post(
          "/run/project/course/paperRule/updatePaperTime",
          param
        ).then((res) => {
          if (res.status == 0) {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.dialogRandom = false;
            this.getDataAll();
            this.dialogExamination = false;
            this.paperTrue = true;
          }
        });
      } else {
        let params = this.getParams();
        this.$post(
          this.projectCoursePaperRuleId
            ? "/run/project/course/paperRule/updateProjectCourseFinalTestRandomRule"
            : "/run/project/course/paperRule/addProjectCourseFinalTestRandomRule",
          params
        ).then((res) => {
          // ;
          if (res.status == 0) {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.dialogRandom = false;
            this.getDataAll();
            this.dialogExamination = false;
          }
        });
      }
    },
    dohandleOk() {
      this.dialogRandom = false;
      this.ruleForm = {
        paperSource: "", //试卷类型
        paperName: "", //试卷名称
        questionBankId: "", //题库Id
        paperScore: "", //通过分数
        paperTotalScore: "", //总分
        // 随机抽题时各题型的参数
        paperObj: {
          SinglechoiceNum: "0", //单选题目数量
          SinglechoiceScore: 0, //单选题目分数
          MultiplechoiceNum: "0", //多选题目数量
          MultiplechoiceScore: 0, //多选题目分数
          judgeNum: "0", //判断题目数量
          judgeScore: 0, //判断题目分数
          fillNum: "0", //填空题目数量
          fillScore: 0, //填空题目分数
        },
        value2: "",
        sourceValue: "", //题目来源
        questionPaperId: "", //试卷Id
      };
      this.choiceNum = 0;
      this.judgeNum = 0;
      this.multiselectNum = 0;
      this.completionNum = 0;
      this.paperTrue = true;
    },
    // 基本信息 - 查看参数
    checkParameters() {
      this.$router.push({
        path: "/web/operate/seeClassCurriculumParameterInfo",
        query: {
          paraId: this.Form.originalParameters.paraId,
          stu: "edit"
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.formCom {
  .formCell {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 50%;
    }
  }

  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }

  .el-cascader {
    width: 100%;
  }

  .el-progress {
    width: 200px;
  }

  .chapterl-trees {
    padding: 15px 15px 15px 0;

    .el-tree-node__content {
      height: 36px;
    }

    .draggable-item {
      margin-left: 5px;

      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      .showbtn {
        color: #fff;
      }

      margin-left: 15px;

      &>em,
      .handle>em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .chapteru-lists {
    padding: 15px 15px 15px 0;

    .el-divider--horizontal {
      margin: 5px;
    }

    .cl-subhint {
      display: flex;

      span {
        font-size: 14px;
        color: #666;

        &:nth-child(2) {
          margin-left: 15px;
        }
      }

      em {
        margin: 0 5px;
      }
    }

    .cl-btns {
      i {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }

  .pageForm-btns {
    padding: 15px 0;
    text-align: center;

    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}

.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}

.originalParameter {
  .el-form-item__label {
    color: red;
  }
}
</style>
<style lang="less" scoped>
.btn-box {
  padding: 50px 0 10px;
  display: flex;
  justify-content: center;

  button {
    padding: 12px 30px;
  }

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}

/deep/ .el-table .warning-row {
  color: red;
}

.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
</style>
