<template>
  <!-- 高级搜索 -->
  <el-dialog
    :visible.sync="centerDialogVisible"
    width="90%"
    top="0"
    center
    @close="docancle"
    title="调查问卷"
    :close-on-click-modal="false"
  >
     <div class="CourseTableBox CourseTableBox2 CourseTableBox3">
    <section class="CourseTable">
      <div class="operationControl">
        <div class="searchbox" style="padding:0">
          <div title="问卷名称" class="searchboxItem ci-full">
            <span class="itemLabel">问卷名称:</span>
            <el-input v-model="paperName" type="text" size="small" placeholder="问卷名称" />
          </div>
          <div>
            <el-button class="bgc-bv" @click="getDataList()">查询</el-button>
            <el-button class="bgc-bv" @click="deleteList()">清除问卷</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            height="520px"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            :header-cell-style="tableHeader"
          >
            <el-table-column label width="50px" align="center" >
              <template slot-scope="scope">
                <el-radio
                  v-model="radio"
                  :label="scope.row.paperId"
                  @change="radioChange(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="问卷名称" align="center" show-overflow-tooltip prop="paperName"/>
          
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    </section>
    <section class="paperMation">
      <h3>已配置问卷</h3>
      <div class="paperMationDetail">
        <div title="问卷名称" class="testTime">
          <span class="itemLabel" style="display: inline-block;word-spacing:5.25px">问&emsp;&emsp;&emsp;卷&emsp;&emsp;&emsp;名&emsp;&emsp;&emsp;称 ：</span>
          <span>{{form.paperName}}</span>
        </div>
      </div>
      <h3>问卷内容信息</h3>
      <div class="ovy-a " style="height:450px">
           <div class="flex1" id="list-box" style="min-width: 1050px;">
              <div class="list" v-for="(item, index) in form.surveyQuestionList" :key="index">
                <div class="list-item df">
                  <!-- <span
                  :class="tixing {item.questionType == 0? 'tixing1':item.questionType == 1 ? 'tixing2':item.questionType == 2 ? 'tixing3':item.questionType == 3 ? 'tixing4':''}"
                  >{{ $setDictionary("QUESTIONTYPE", item.questionType) }}</span
                  >-->
                  <span
                    class="tixing"
                    :class="{
                    tixing1: item.questionType == 10,
                    tixing2: item.questionType == 20,
                    tixing3: item.questionType == 30,
                  }"
                  >{{ $setDictionary("SR_QUESTION_TYPE", item.questionType) }}</span>
                  <div class="subject flex1">
                    <div class="subject-title">
                      <span>{{ indexMethod(index) }}、{{ item.questionName }}</span>
                    </div>
                    <div class="option" v-if="item.questionType == 10">
                        <span v-for="(item1,index) in item.optionNameList" :key="index" style="display:flex;flex-direction: column;">
                            {{optionFM(index)}}、{{item1.optionName}}
                             <el-image
                            style="width:50px"
                            v-if="item1.optionImg"
                            class="qbimg"
                            :src="
                              item1.optionImg ||
                                require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image>
                        </span>
                    </div>
                    <div class="option" v-if="item.questionType == 20">
                        <span v-for="(item1,index) in item.optionNameList" :key="index" style="display:flex;flex-direction: column;">
                            {{optionFM(index)}}、{{item1.optionName}}
                             <el-image
                            style="width:50px"
                            v-if="item1.optionImg"
                            class="qbimg"
                            :src="
                              item1.optionImg ||
                                require('@/assets/develop.png')
                            "
                            fit="contain"
                          ></el-image>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
        
    </section>
    </div>
   
    <span slot="footer" class="dialog-footer">
      <el-button class="bgc-bv" @click="docancle()">取 消</el-button>
      <el-button class="bgc-bv" @click="doOk">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";

export default {
  name: "ChestLotteryList",
  components: {
    // Empty,
  },
  mixins: [List],
  data() {
    return {
      editBtn: true, //
      centerDialogVisible: false,
      paperList: [],
      parperId: "",
    paperName:'',
    radio:'',
    projectCourseId:'',
    form:{},
    buttonclickVisible:false,
    };
  },
  created() {
    
  },
  mounted() {
    
  },
 
  computed: {},
  methods: {
    init() {
        this.getDataList();
        this.getTableHeight()
    },
    showPopUp(row,stu,radio) {
      const that = this;
      if(stu== '1') {
          that.radio = row.paperFinalId;
           that.projectCourseId = row.projectCourseId;
          this.getDataContent()
      } else {
          that.radio = radio;
           this.getDataContent()
      }
      that.stu = stu;
      that.centerDialogVisible = true;
    },
     getDataList() {
      const params = {
        paperName: this.paperName || "",
      };
      this.$post('/course/survey/config/getPaper',params).then(ret => {
          this.tableData = ret.data ||[]
      })
    },
     getDataContent() {
      const params = {
        paperId: this.radio || "",
      };
      this.$post('/course/survey/config/getPaperContent',params).then(ret => {
          this.form = ret.data || {}
      })
    },
     radioChange(row) {
      this.radio = row.paperId;
      this.getDataContent()
       this.$forceUpdate();
    //   this.paperName = row.paperName;
    },
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      }else if (msg == 5) {
        return "F";
      } 
    },
    docancle() {
      this.centerDialogVisible = false;
      console.log(this.buttonclickVisible);
      if(this.buttonclickVisible) {
           this.$emit("eventBus1", true);
      } else {
         this.$emit("eventBus1", false);
      }
     
    },
    closeDialog1() {
      this.centerDialogVisible = false;
       this.buttonclickVisible = true,
      this.$emit("eventBus1", true);
    },
    deleteList() {
        this.radio = ''
    },
     doOk() {
       console.log(this.stu);
       if(this.stu ==  '1') {
             const parmar = {
          paperId: this.radio,
          projectCourseId: this.projectCourseId,
          // paperStartTime:'',
          // paperEndTime:''
          
        };
        this.$post("/course/survey/config/final-term", parmar)
          .then(ret => {
            this.$message({
              message: "配置期末问卷成功",
              type: "success"
            });
            this.closeDialog1();
            this.$emit("eventBus");
            this.$emit("getData");
            this.$emit("getDatasall");
              this.$emit("eventBus1", true);
          })
          .then(err => {
            return;
          });
       }  else {
         this.buttonclickVisible = true
            const { radio } = this;
             this.closeDialog1();
            this.$emit("eventBus", radio);
            this.$emit("eventBus1", true);
       }
      
    },
     getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  }
};
</script>
<style lang="less">


.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: 9.375rem;
        }
      }
    }
  }
}
.CourseTableBox3 {
.searchbox {
  padding: 0.9rem 0 0 1.775rem;
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}
}

.tixing {
  font-size: 14px;
  height: fit-content;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.showtitle {
      color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
}

</style>
<style lang="less" scoped>
.el-dialog--center .el-dialog__body {
    padding: 10px 25px 0 !important;
}
.CourseTableBox2 {
    .CourseTable {
    width:30%;
}
.paperMation {
    width:69%;
    .paperMationDetail {
        padding:0;
    }
}
}

</style>
